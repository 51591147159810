


.title{
    display: flex;
    justify-content: space-between;
}
.Combine{
    height: 114px;
    width: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px
}
.iconbtn{
    position: relative;
    border-radius: 18px;
    padding: 1rem;
    background: rgba(231, 231, 231, 1) !important;
    transition: 0.5s;
    width: 90px;
    height: 90px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/* .iconbtn {
    justify-content: center;
} */

.iconbtn:hover{
     box-shadow: 0 0 11px rgba(33,33,33,.2); 
     background-color :rgba(0, 108, 183, 1) !important;
     color: white ;
     border-radius: 100px;

}
.share-text-box{
    display: flex;
    align-items: center;
    font-family: Roboto;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-align: left;

}
.icon{
    position: relative;
    z-index: 10;
    width: 40.21px;
    height: 40px;
    color: rgba(59, 89, 152, 1);
    
}
.icon-name{
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-align: left;

}
.input-field{
    width:60%;
    height: 60px;
    /* box-sizing: border-box; */
    /* position: absolute; */
    font-size: 16px;
    /* border: white !important; */
    /* border-bottom: white; */
    left:1rem;
    font-family: Roboto;
    font-weight: 400;
    letter-spacing: 0.5px;
    /* border-radius: 100px !important; */
    /* border: 1px; */
    /* padding: 8px 10px 8px 40px; */
}
.input-field input:disabled {
    color: black;
    font-weight: 600;
}
.btn-copy{
    /* position: relative; */
    /* top: 7px; */
    /* right: 2rem; */
    width:  127px;  
    height:  44px;
    padding:  0 16px;
    gap: 16px;
    border-radius: 50px !important;
    opacity: 0px;
    background: rgba(0, 108, 183, 1);
    
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 1.25px;
    text-align: center;
}
.copy-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 10px 8px 40px;
    width: 100% !important;
    border-radius: 100px !important;
    border: 1px solid rgba(217, 217, 217, 1);
}
.Combine-conatiner{
    display: flex;
    flex-direction: column;
    gap: 44px;
}



.input-field{
    height: fit-content !important;
}



