@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');


.pro2-page{
background-color: #FFFFFF;
box-shadow: 5px 4px 4px 2px rgba(0, 0, 0, 0.05);
border-radius: 10px;
width: 85%;
margin-top:25px;
}

.pro-heading1{
    font-weight: 600;
    font-family: 'Roboto', sans-serif;
    font-size: 24px;
    padding: 1%;
    padding-left: 23px;
}

.pro-BasicHr {
    border: 1px solid darkgrey;
    margin-top: -10px;
    padding-left: -74px;
    margin-left: 1px;
}

.pro-input{
    background-color: #EBEBEB;;
    padding:1.5%;
    }

.exp-pro input{
    background-color: #EBEBEB;;
padding:1%;
border:none;
border-radius: 4px;
width:337px;
}

.pro-all-inputs{
    display: flex;
    flex-direction: column;
    /* gap: 20px; */
    margin-top:20px;
    padding-left: 23px;
}
.basic-resume{
    color: #000000;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    font-family: 'Roboto', sans-serif;
}

.jobeze-candidate-details-p{
    width: 18%;
    display:flex;
    flex-direction: column;
    gap: 16px;
    color: var(--black-06, rgba(0, 0, 0, 0.60));
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px; 
}
.professional_Color{
    color: var(--black-06, rgba(0, 0, 0, 0.60));
}


.jobeze-details-block-p{
 display: flex;
 gap: 48px;
 padding-left: 24px;
 padding-bottom: 24px;
}


.drawer-buttons-p{
    gap: 10px;
    display: flex;
    margin-top: 1vh;
    padding-left: 22px;
  
}
/* @media only screen and (min-width: 360px)  and (max-width: 376px) {
    .professional-drawer-inputs{
        margin-left: 56px !important;
    }
    .css-1bmsl3s {
        gap:14px !important
    }

    .css-d3wcwz-MuiTypography-root{
        margin-left: 56px !important;
    }

    .css-iy2t6j-MuiSlider-root {
        margin-left: 56px !important;
    }
    .drawer-buttons-p{
       padding-left: 80px !important;
    }
    
  } */

/* 
  @media only screen and (min-width: 390px)  and (max-width: 400px) {
    .professional-drawer-inputs{
        margin-left: 25px !important;
    }
    
  } */

  /* @media only screen and (max-width: 429px){
    .drawer-buttons-p{
        padding-left: 22px !important;
     }
  }

  @media only screen and (max-width: 394px){
    .drawer-buttons-p{
        padding-left: 47px !important;
     }
  }

  @media only screen and (max-width: 376px){
    .drawer-buttons-p{
        padding-left: 72px !important;
     }
  } */