.main-search-box {
  background-color: #f3f8fb;
  display: flex;
  height: 40px;
  width: 445px;
  border: 1px solid #00000042;
  border-radius: 22px;
  position: relative;
  top: 0px;
  left: 43px;
}

.sub-search-box {
  padding: 0 0px 0 9px;
  display: flex;
  align-items: center;
}

.btn-search-box {
  background-color: #006cb7;
    color: #fff;
    border-radius: 50%;
    border: none;
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    position: relative !important;
    right: -26px !important;
}

.btn-search-box-icon {
  font-size: 17px !important;
}

.search-designation {
  display: flex;
  /* position: relative;
    left: 8px; */
  margin-left: 2%;
  justify-content: center;
  align-items: center;
}

.search-designation input {
  height: 36px;
  background-color: #f3f8fb;
  border: none;
  margin-right: 4%;
}

.search-location {
  display: flex;
  position: relative;
  left: 18px;
  justify-content: center;
  align-items: center;
}

.search-location input {
  height: 36px;
  background-color: #f3f8fb;
  border: none;
}

.designation-input::placeholder {
  font-family: Roboto;
  font-size: 13.5px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: left;
  color: #00000099;
  position: relative;
  bottom: 2px;
}
.location-input::placeholder {
  color: #00000099;
  font-family: Roboto;
  font-size: 13.5px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: left;
  position: relative;
  bottom: 2px;
}

.location-input {
  width: 137px;
}

.error-message2 {
  background: #333;
  text-align: center;
  color: #fff;
  font-size: 12px;
  letter-spacing: 0.5px;
  border-radius: 8px;
  z-index: 9999;
  position: absolute;
  top: 47px;
  left: -27px;
}

.error-message2::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #333 transparent;
}

.ul-menulist{
  position: relative;
  left: 14rem;;
}
.ul-menulist1{
  position: relative;
  left:2rem;
}


.clear-icon {
  position: absolute;
    /* top: -24%; */
    left: 45%;;
    transform: translateY(-6%);
    cursor: pointer;
    font-size: 35px;
}
.clear-icon1 {
  position: absolute;
    /* top: -24%; */
    left: 166px;
    transform: translateY(-7%);
    cursor: pointer;
    font-size: 35px;
}


.profile-dropdown{
  margin-left:1%;
}

.ul-dropdown{
  left: -117px !important;
}


