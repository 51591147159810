.dropdown-location {
    position: absolute;
    width: 250px;
    border-radius: 10px;
    top: calc(100% + 10px);
    left: 50%;
    z-index: 1000;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
}

.dropdown-menu-location {
    /* width: 210px !important; */
    background-color: white;
    border-radius: 10px;
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
    /* Assuming each item's height is 40px */

}




.dropdown-item-location {
    padding: 0.5rem 1rem;
    font-weight: 400;
    color: #303030;
    text-align: inherit;
    white-space: nowrap;
    background-color: white !important;
    border: 0;
    cursor: pointer;
}

.dropdown-item-location:hover,
.dropdown-item-location:focus {
    background-color: #006cb7 !important;
    color: white !important;
}

/* Mobile Styles */
@media only screen and (max-width: 768px) {
    .dropdown-location {
        position: relative;
        left: 0%;
        border: none;
        max-height: 300px;
    }

    .dropdown-menu-location {
        width: 100%;
        border-radius: 4px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        padding: 0;
        margin: 0;
    }


    .dropdown-item-location {
        padding: 0.5rem 1rem;
        font-weight: 400;
        color: #303030;
        text-align: inherit;
        white-space: nowrap;
        background-color: transparent;
        border: 0;
        cursor: pointer;
    }

    .dropdown-item-location:hover {
        background-color: #006cb7 !important;
        color: white !important;
    }
}