@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');




.Box-page{
    /* display: flex; */
    background-color: #f6f6f6;
}

.sectionbox1-page{
    background-color: #f6f6f6;
    flex:25%;
    padding-left:8%;
    padding-right:1%;
    padding-top: 0.8%;
    padding-bottom:0.8%;
}

.profile-page{
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.12);
    border-radius: 20px;
    background-color: white;
    padding-bottom: 6%;
}
.profilesection-page{
    padding-top: 12px;
}
.profileimg-page{
    width:115px;
    height:115px;
    background: #D9D9D9;
    margin-left:auto;
    margin-right: auto;
    overflow: hidden;
}

.titleprofile-page{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    color:#000000;
}

.profilemail-page{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 13.5px;
    margin: auto;
    color: rgba(0, 0, 0, 0.6);
}
.sectionD{
    margin: auto;
    text-align: center;
    font-weight: 600;
}
.D1{
    width: 90%;
    overflow: hidden;
    margin: auto;
    text-overflow: ellipsis;
    font-size: 18px;
    white-space: nowrap;
}
.D2{
    text-overflow: ellipsis;
    width: 90%;
    overflow: hidden;
    color: rgba(0, 0, 0, 0.6);
    white-space: nowrap;
}
.complete-section-page{
    display: flex;
    height: 30px;
    justify-content: center;
    gap: 10px;
    flex-direction: row-reverse;
}

.complete1-page{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight:600;
    font-size: 14px;
    line-height: 10px;
    color: rgba(0, 0, 0, 0.6);
    margin-top: 9px;
}

.complete2-page{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #006CB7;
    margin-top: -5px;
}

.profilebutton-page{
    font-family: 'Roboto', sans-serif;
    width: 70%;
    margin-top: 15px !important;
    margin-left: 35px !important;
}

.jobcategory-page{
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.12);
    border-radius: 20px;
    margin-top: 15px;
    background-color: white;
    overflow: hidden;
}

.checkboxlist{
    margin-left: 20px;
    margin-top: 10px;
    overflow-y: auto;
    overflow-x: hidden;
    height: 44vh;
    /* height: 22vw; */
}



.checkboxlist::-webkit-scrollbar {
    width: 6px;
}
  
.checkboxlist::-webkit-scrollbar-track {
    background: white;
}

.checkboxlist::-webkit-scrollbar-thumb {
    background-color: #006CB7;
    border-radius: 20px;
}

.jbscroll {
    height: 80vh;
    overflow-y: scroll;
}
.jbscroll::-webkit-scrollbar {
    width: 7.5px; 
}
  
.jbscroll::-webkit-scrollbar-track {
    background: transparent;
}

.jbscroll::-webkit-scrollbar-thumb {
    background-color: #006CB7;
    border-radius: 20px;
}

.jbscrollsave {
    height: 80vh;
    overflow-y: scroll;
    padding-bottom: 5rem;
}
.jbscrollsave::-webkit-scrollbar {
    width: 7.5px; 
}
  
.jbscrollsave::-webkit-scrollbar-track {
    background: transparent;
}

.jbscrollsave::-webkit-scrollbar-thumb {
    background-color: #006CB7;
    border-radius: 20px;
}

/* .jbscroll2 {
    overflow-y: auto;
}
.jbscroll2::-webkit-scrollbar {
    width: 8px;
}
  
.jbscroll2::-webkit-scrollbar-track {
    background: #f6f6f6;
}

.jbscroll2::-webkit-scrollbar-thumb {
    background-color: #006CB7;
    border-radius: 20px;
} */

.titlejobcategory-page{
    font-style: normal;
    font-weight: 800;
    font-size: 22px;
    line-height: 28px;
    font-family: 'Roboto', sans-serif;
    color: #000000;
    padding-left: 30px;
    padding-top: 15px;
}


.checklist-page{
    display: flex;
    align-items: baseline;
   margin-left: 10px;
    gap:10px;
    font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 12px;
font-family: 'Roboto', sans-serif;
}

.checklist-page input{
    height:15.5px;
    width:18px;
    background: #D9D9D9;
    border: 1px solid #000000;

}

.checklist-page p{
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
}

.sectionbox2-page{
    /* padding-top: 30px; */
    background-color: #f6f6f6;
    height: 89vh !important;
    /* flex: 75% 1; */
    overflow-y: auto;
}

.section-heading-page{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 600;
    /* font-size: 24px; */
    /* line-height: 38px; */
    /* padding-left: 22px; */
}
.grid-page{
    display: grid;
    grid-template-columns: repeat(3, 30%);
    padding-bottom: 2%;
}