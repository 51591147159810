@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');
.forgetpage{
    display: flex;
    justify-content: center;
    margin-top:8%;
}
.forgetbox{
background: #FFFFFF;
box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.16);
border-radius: 15px;
width: 25%;
}

.forgetpagetitle{
font-style: normal;
font-family: 'Roboto', sans-serif;
font-weight: 600;
font-size:26px;
line-height: 49px;
text-align: center;
color: #000000;
margin-top: 10%;
}

.Forgetsavebutton{
    width:314px;
    padding:4%;
    background-color: 
    #2D65BC;
    color: white;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
font-weight: 500;
font-size: 24px;
line-height: 28px;
border-radius: 4px;
border: none;
margin-left: 8%;
margin-top: 15%;

}

.xyz{
    padding-left: 8%;
}

.xyz1{
    padding-left: 8%;
}