@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');


.status-profile{
    display: flex;
    color:  #000000;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    gap: 15px;
    margin-top: 17px;
    /* margin-left: 126px; */

}

.status-profile img{
    height:1%;
   
    margin-top: 0.5%;
}

.status-section{
    /* margin-right: 110px; */
    /* margin-left: 110px; */
    height: 14vh;
    background-color: #FFFFFF;
    margin-top: 4px;
    display: flex;
    border-radius: 10px;
}

.status-section-title{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #000000
}

.company-title{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;  
    color: gray;
    
}

.status-block{
    width:30px;
    height:26.29px;
    background-color:#D9D9D9;
    margin-top: -6px;
}

.status-section1{
    width: 350px;
    margin-left: 33px;
    margin-top: 15px;
}

.view-detail{
   
    color: #181818;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
}

.last-status{
    margin-left: 48%;
    margin-top: 2.2%;
    gap: 15px;
}

.status-section-title-new{
    color:#000000;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 8px;

}

.dots{
    padding-top: 26px;
    margin-left: 25px;
    cursor: pointer;
}