@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
html {
  scroll-behavior: smooth;
}
.fz {
  font-family: "Work Sans", sans-serif;
}
.btn:focus {
  outline: none;
  box-shadow: none !important;
}
.searchbar {
  background-color: white;
  border-radius: 5%;
  height: 5%;
  width: 20%;
}
.errornewhead {
  position: relative;
}
.error-message {
  background: #333;
  text-align: center;
  color: #fff;
  font-size: 11px;
  letter-spacing: 1px;
  border-radius: 8px;
  z-index: 9999;
  position: absolute;
  top: 80px;
  left: 1%;
}

.error-message::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #333 transparent;
}
.status-profile-viewjobs1 {
  background-color: #f1f1f1;
  font-weight: 600;
}
.button {
  background-color: blue;
  height: 40px;
  width: 100px;
  border: none;
  color: white;
}
/* ::-webkit-scrollbar {
  display: none;
} */
.dot {
  /* height: 4.3vh; */
  width: 28px;
  height: 28px;
  display: inline-block;
  color: white;
  font-size: 18px;
  margin: 0 0 0 10px;
  background-color: #2d65bc;
}
/* .dot {
  width: 17%;
  border-radius: 50%;
  display: inline-block;
  color: #2D65BC;
  background-color: white;
} */

.btn-clr {
  background-color: #006cb7;
}
.logo {
  /* font-family: 'Roboto'; */
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 49px;
  color: #000000;
}
input:focus {
  outline: none;
}
::placeholder {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 28px;
  color: "light grey";
  opacity: 0.4;
}

.divTopStyle {
  font-family: "Futura";
  font-style: normal;
  font-weight: 700;
  font-size: 72px;
  line-height: 122.69%;
  color: #000000;
}
.cardTitle {
  /* font-family: 'Roboto'; */
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 33px;
  color: #000000;
  width: 216px;
  height: 30px;
}
.cardDesc {
  /* font-family: 'Roboto'; */
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 23px;
  display: flex;
  align-items: center;

  color: #000000;
}

.jobBC {
  font-weight: 800;
  font-size: 38px;
  line-height: 49px;
  color: #000000;
  justify-content: center;
  font-family: "Roboto", sans-serif;
}
.fx {
  font-family: "Roboto", sans-serif;
}
.candidateDesc {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  text-align: center;

  color: #000000;
}
.cursor {
  cursor: pointer;
}

@media (max-width: 576px) {
  .postedmobile {
    margin-bottom: 45px;
  }
  .forgetpswrdwidth {
    width: 80% !important;
  }
}
@media (max-width: 992px) {
  .postedmobile {
    margin-bottom: 12px;
  }
}
@media (max-width: 992px) {
  .fontFind {
    font-size: 58px;
    font-weight: 700;
    line-height: 50px;
  }
}
@media (min-width: 992px) {
  .fontFind {
    font-size: 57px;
    font-weight: 600;
    line-height: 70px;
    word-spacing: 5px;
  }
  .fontFind2 {
    font-size: 47px;
    font-weight: 600;
    line-height: 62px;
  }
}
@media (max-width: 992px) {
  .searchStyles {
    background-color: white;
    height: 75px;
    width: 550.64px;
    border-radius: 113px;
  }
}

@media (min-width: 992px) {
  .searchStyles {
    background-color: white;
    height: 68px;
    width: 648px;
    border-radius: 113px;
    box-shadow: 0px 13px 13px 4px rgba(0, 0, 0, 0.03);
  }
}

@media (max-width: 992px) {
  .search-btn {
    width: 60.53px;
    height: 35px;
    border-radius: 4px;
    font-size: 15px;
  }
}

@media (min-width: 992px) {
  .search-btn {
    width: 128.53px;
    height: 58px;
    border-radius: 4px;
    font-size: 24px;
  }
}

@media (max-width: 992px) {
  .signup-btn {
    width: 100.53px;
    height: 45px;
    border-radius: 4px;
    font-size: 15px;
  }
}

@media (min-width: 992px) {
  .signup-btn {
    width: 158.53px;
    height: 61px;
    border-radius: 4px;
    font-size: 24px;
  }
}

/* @media (max-width : 900px) {
  .inputStyles{
    height:10px;
    width:200px;
  }
} */

@media (min-width: 350px) {
  .inputStyles {
    height: 40px;
    width: 600px;
  }
}

.homeLogin {
  background: #006cb7;
  border-radius: 4px;
}

@media (max-width: 992px) {
  .container2 {
    height: "125px";
    width: "225px";
  }
  .mobisearch {
    /* width: 75%; */
    margin: 0px auto 80px auto;
  }
  .profileheadingEdit {
    padding-left: 0;
  }
  .editbtnss {
    width: 25%;
    margin: 0 auto;
  }
}

@media (min-width: 992px) {
  .container2 {
    height: "550px";
    width: "550px";
  }
  .profileheadingEdit {
    padding-left: 7%;
  }
}

/* 
.scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: red;
} */

/* html, body {margin: 0; height: 100%; overflow: hidden} */

.lastDiv-skills {
  display: flex;
  padding-top: 15px;
  gap: 20px;
  margin-top: -19px;
  margin-left: 115px;
}
.lastDivimg-skills {
  width: 24px;
  height: 24px;
}
.hrTag-skills {
  border: 1px solid black;
  height: 30px;
  margin-top: -2px;
}
.userText-skills {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
}
/* .checklist-page input[type='checkbox'] {
  accent-color: red;
} */

.inputF {
  display: flex;
}
.inputF-1 {
  display: flex;
  padding-top: 0px;
  padding-left: 9px;
}
.inputF-1 input {
  width: 208px;
  border: none;
  outline: none;
  height: 60px;
}
.inputF-2 {
  display: flex;
  padding-top: 17px;
  padding-left: 12px;
}
.inputF-2 input {
  width: 200px;
  border: none;
  outline: none;
  margin-top: -18px;
  height: 60px;
}
.inputF-btn {
  margin-left: -32px;
  padding-top: 8px;
}
.inputF-btn button {
  /* background-color: #006CB7; */
  /* color: white; */
  width: 137px;
  height: 46px;
  /* border: none; */
  font-size: 19px;
  /* border-radius: 40px; */
  font-family: "Roboto", sans-serif;
  /* font-weight: 600; */
}
/* .MainDivC {
  height: 75vh  ;
} */
.MainDivC-1 {
  display: grid;
  grid-template-columns: repeat(4, 20%);
  justify-content: center;
}
.MainDivC-2 {
  display: grid;
  grid-template-columns: repeat(4, 20%);
  justify-content: center;
}
.MainDivC-3 {
  display: grid;
  grid-template-columns: repeat(4, 20%);
  justify-content: center;
}
.MainDivCButton {
  display: flex;
  justify-content: center;
  column-gap: 15px;
  /* padding-top: 5vh; */
}
.MainDivCButton button {
  border-radius: 50%;
  width: 15px;
  height: 15px;
  border: none;
}
.Fouternew {
  padding: 2px;
  position: fixed;
  background-color: white;
  bottom: 0px;
  color: black;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-right-style: none;
}
.FouternewHome {
  padding: 2px;
  background-color: white;
  bottom: 0px;
  color: black;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-right-style: none;
}
iframe {
  width: 135px !important;
}
.btn {
  border-radius: 4px;
}
.latest-button:hover {
  outline: 1px solid;
  background-color: transparent !important;
}
.showRight {
  text-align: center;
  margin: auto;
  width: 200px;
  border: 0;
  line-height: 35px;
  outline: none;
}
.showRight li a {
  transition: all 0.3s;
  background-color: none !important;
  letter-spacing: 0px;
}
.showRight li a:hover {
  background: transparent;
  letter-spacing: 0.7px;
  color: #1976d2;
}
.showRight li .logouthover:hover {
  font-size: 18px;
}

.applicationShowRight {
  text-align: center;
  width: 200px;
  border: 0;
  line-height: 35px;
  margin-top: 13px !important;
  outline: none;
}
.applicationShowRight li a {
  transition: all 0.3s;
  font-weight: bold;
  background-color: none !important;
  letter-spacing: 0px;
}

.applicationShowRight li a:hover {
  background: transparent;
  letter-spacing: 0.7px;
  color: #1976d2;
  /* background-color: #0d6efd !important; */
}
.viewfullpagehover {
  text-decoration: none;
  color: #000000;
}
.viewfullpagehover:hover {
  text-decoration: underline !important;
  color: #0d6efd !important;
}
hr:not([size]) {
  height: 0;
}
.OtherSkill {
  width: 37%;
  margin-left: 23px;
  margin-top: 28px;
}
.cb {
  box-shadow: 5px 4px 4px 2px rgba(0, 0, 0, 0.05) !important;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  /* color: var(--bs-card-color); */
}
.recommended_cb {
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
}
.cardP {
  width: 100%;
}

.pagNav {
  justify-content: center;
  margin-left: -258px;
  margin-top: 0rem !important;
  cursor: pointer;
}
.vl {
  border-radius: 10px;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.12);
}
.jobApplyCard {
  height: 78vh;
  overflow: hidden;
}
.bgBack {
  background-color: #f6f6f6;
}

.eyePassword {
  width: 18px;
  height: 14px;
  left: 58%;
  top: 45%;
  position: absolute;
  cursor: pointer;
}

.candidate-about-us {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 42px;
  line-height: 49px;
  display: flex;
  margin-top: 30px;
  width: 623px;
  margin-left: auto;
  margin-right: auto;
}

.candidate-about-heading1 {
  /* font-family: 'Roboto', sans-serif; */
  color: #000000;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 21px;
  margin-left: auto;
  margin-right: auto;
}
.heightforcard {
  height: 25ch;
}
.AlertShow {
  width: 100%;
}

.Resend-title {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 40px;
  width: 345px;
  margin-left: 29px;
  margin-top: 20px;
}

.resend-page {
  width: 532px;
  height: 366px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.resend-button {
  background-color: #006cb7;
  color: white;
  border: none;
  border-radius: 4px;
  margin-left: 121px;
  margin-top: 100px;
  width: 250px;
  height: 50px;
  padding: 20px, 40px, 20px, 40px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  font-family: "Roboto", sans-serif;
}

.cross-resend {
  height: 19px;
  width: 19px;
  margin-left: 14%;
  margin-top: 6%;
}
.fontsizehtml {
  /* font-size: 16px !important; */
}
.ApplicationPage {
  background-color: whitesmoke;
  /* height: 92vh; */
  overflow-y: auto;
}

.backimage {
  background-image: url("./../public/1.svg");
  background-size: cover;
  height: 250px;
}
.noJob {
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 38px;
  padding-top: 15%;
}
.choose-page {
  border: 1px dashed #d9d9d9;
  height: 14vh;
  width: 60%;
  margin-top: -3.5%;
}

.imagefile {
  height: 19px;
  display: flex;
  justify-content: center;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.drop-title2 {
  color: rgba(0, 0, 0, 0.6);
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}
.skipNav {
  position: absolute;
  padding-left: 39%;
  font-size: 17px;
  font-weight: 500;
  cursor: pointer;
}
.Dropshow {
  /* height: 30vh; */
  overflow-y: auto;
}

@media (min-width: 1400px) {
  .container {
    max-width: 86% !important;
  }
}

.css-f3591o-MuiPagination-root {
  padding-bottom: 2%;
}
.filterchipDropdown1 {
  margin-top: 2px !important;
  width: 200px;
}
.filterchipDropdown {
  margin-top: 2px !important;
  width: 300px;
}
.jobposttype li label {
  cursor: pointer;
}
.jobposttype {
  list-style: none;
  padding-left: 0.1rem !important;
}
.dropUl {
  padding-left: 0.1rem !important;
  /* height: 14vw; */
  overflow-y: scroll;
  list-style: none;
}
.dropUl li label {
  cursor: pointer;
}
.dropUl::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.dropUl::-webkit-scrollbar-track {
  background-color: transparent !important;
}
.dropUl::-webkit-scrollbar-thumb {
  height: 55px;
  border-radius: 10px;
  background-color: #006cb7;
}
.topClass {
  display: flex;
  justify-content: center;
  column-gap: 15%;
}

.jbscroll3 {
  overflow-y: auto;
  /* width: 106.666667% !important; */
}
.jbscroll3::-webkit-scrollbar {
  width: 8px;
}

.jbscroll3::-webkit-scrollbar-track {
  background: #f6f6f6;
}

.jbscroll3::-webkit-scrollbar-thumb {
  background-color: #006cb7;
  border-radius: 20px;
}

.footerMain {
  background-color: #f5f5f5;
  width: 100%;
  overflow: hidden;
}

.footer-1-box {
  margin: 0px 8%;
}

.newsletter-div {
  /* height: 80px; */
  /* column-gap: 50%; */
}

.email-input {
  height: 48px !important;
  width: 312px !important;
  padding: 8px 12px 8px 12px;
  border: 1px solid rgba(0, 0, 0, 0.42);
  border-radius: 4px;
  outline: none;
  background-color: #f5f5f5;
}

.Subscribe-button {
  cursor: pointer;
  height: 48px !important;
  border: 1px solid rgba(25, 118, 210, 0.5);
  border-radius: 4px;
  padding: 0px, 16px, 0px, 16px;
  color: #006cb7;
  font-weight: 600;
  font-size: 14px;
  width: 121px;
  font-family: "Roboto", sans-serif;
  background-color: #f5f5f5;
}

.Subscribe-button:hover {
  background-color: #006cb7;
  color: white;
}

.policy-text {
  font-weight: 600;
  font-size: 14px;
  float: right;
  margin-right: 53px;
  margin-top: 16px;
  font-family: "Roboto", sans-serif;
}

.ul-list {
  padding: 0px;
  padding-bottom: 25px;
  list-style-type: none;
  margin: 0px;
}

.spreation-line {
  margin-top: 40px;
  margin-right: 40px;
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.common-font {
  color: rgba(0, 0, 0, 0.6);
  font-weight: 600;
  font-size: 16px;
}

.common-font-2 {
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #000000;
  cursor: pointer;
}
.common-font-2:hover {
  color: #006cb7;
  text-decoration: underline;
}
.common-font-2 a:hover {
  color: #006cb7 !important;
  text-decoration: underline !important;
}
.CNN {
  font-family: "Roboto", sans-serif;
}

/* .footer-end-new{
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
  margin-bottom: 76px;
} */

.footer-end-col1 {
  font-weight: 600;
  font-style: 14px;
  font-family: "Roboto", sans-serif;
}
.footer-end-col1:hover {
  text-decoration: underline;
}
.headerMain {
  width: 100%;
  height: 72px;
  overflow: hidden;
  border-bottom: 2px solid rgba(0, 0, 0, 0.3);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.headerSub {
  margin-left: 8%;
  margin-right: 8%;
  margin-top: 14px;
  margin-bottom: 14px;
  display: flex;
  justify-content: space-between;
}
.btm1 {
  font-family: "Roboto", sans-serif;
  height: 44px;
}
.btm1:hover {
  outline: 1.2px solid;
  background-color: transparent !important;
}

.btm2 {
  font-family: "Roboto", sans-serif;
  height: 44px;
}
.privacyPolicy {
  margin-top: 24px;
  width: 83.33%;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  font-family: "Roboto", sans-serif;
  margin-bottom: 112px;
  font-weight: 600;
}
.ppH {
  width: 83.33%;
  margin-top: 72px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  font-weight: 600;
  font-size: 48px;
  line-height: 50px;
  font-family: "Roboto", sans-serif;
}

.contact-complete-blog {
  display: flex;
  background: #ffffff;
  /* justify-content: center; */
  /* gap: 11.2%; */
}

.contact-us-details {
  /* width: 35.88%;
  margin-top: 72px;
  margin-bottom: 72px;
  margin-left: 8%; */
}

.contaact-us-banner {
  width: 32.88%;
  margin-top: 72px;
  margin-bottom: 72px;
}

.Contact-us-title {
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 48px;
  line-height: 50px;
}

.Contact-us-sub-title {
  font-family: "Roboto", sans-serif;
  margin-top: 24px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.Contact-us-name {
  font-family: "Roboto", sans-serif;
  margin-top: 32px;
  font-weight: 600;
  font-size: 16px;
}

.contaact-us-banner {
  background-image: cover;
}

.input-contact-us {
  width: 100%;
  margin-top: 8px;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.42);
  border-radius: 4px;
  height: 41px;
  padding-left: 1.5%;
}

.Contact-us-textarea {
  width: 100%;
  height: 121px;
  margin-top: 8px;
  resize: none;
  outline: none;
  padding-left: 12px;
  padding-top: 8px;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 500;
  border: 1px solid rgba(0, 0, 0, 0.42);
  border-radius: 4px;
}

.Contact-us-checkbox-label {
  display: flex;
  gap: 12px;
  margin-top: 16px;
}

.accept-terms-title {
  color: rgba(0, 0, 0, 0.87);
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 16px;
}

.contact-us-button {
  margin-top: 27px;
  /* color: white; */
  font-family: "Roboto", sans-serif;
  /* background-color: #006CB7; */
  font-weight: 600;
  /* font-size: 14px; */
  /* border-radius: 4px; */
  /* border: none; */
  /* padding: 0px 16px; */
  height: 44px;
  /* width: 18%; */
  letter-spacing: 1.25px;
}

.contact-us-icons {
  background-color: #f2f9fd;
  display: flex;
  gap: 3.33%;
}

.contact-us-email {
  margin-top: 72px;
  margin-left: 8%;
  width: 22%;
}

.contact-us-phone {
  width: 22%;
  margin-top: 72px;
}

.contact-us-office {
  margin-top: 72px;
  width: 22%;
}

.Email-contact-us {
  margin-top: 10%;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 34px;
}

.Emailaddress-contact-us {
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 16px;
}
.Emailaddress-contact-us:hover {
  text-decoration: underline;
}
.JobSeekerList {
  margin-left: -15px;
}
.JobSeekerList li:hover {
  font-weight: 400;
  text-decoration: underline;
}
.JobSeekerList li a::before {
  content: "\203A";
  position: absolute;
  left: 0;
}
.JobSeekerList li a {
  cursor: pointer;
}
/* .dropdownclassesfilter {
  background-color: white !important;
  color: gray !important;
  border: 1.6px solid gray !important;
} */
.dropdownclassesfilter {
  background-color: white !important;
  color: #0d6efd !important;
  border: 1.6px solid #0d6efd !important;
}

.dropdownFilterchip {
  overflow-x: hidden;
  width: 325px;
  margin-top: 5px !important;
}
.cretableoption .css-1n6sfyn-MenuList {
  padding-top: 0;
  padding-right: 5px;
  padding-bottom: 0;
}

.cretableoption .css-1n6sfyn-MenuList::-webkit-scrollbar {
  margin: auto;
  width: 10px;
}
.cretableoption .css-1n6sfyn-MenuList::-webkit-scrollbar-track {
  background: transparent;
}
.cretableoption .css-1n6sfyn-MenuList::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #427cd3;
}
/* .......................... headerlist effects............................................ */

.job-title-modelbox {
  color: var(--black-087, rgba(0, 0, 0, 0.87)) !important;
  font-family: "Roboto", sans-serif !important;
  font-weight: bolder !important;
}
.job-warning {
  font-weight: 600 !important;
}

.Applied-on-modelbox {
  margin-top: 5px !important;
  font-family: "Roboto", sans-serif !important;
  font-weight: 600 !important;
}

.Model-box-confirmation {
  font-family: "Roboto", sans-serif !important;
  font-weight: 600 !important;
}

.active {
  width: fit-content;
  outline: none;
  color: #1976d2 !important;
  font-weight: 700;
  /* text-align: center; */
  border-bottom: 2px solid #1976d2 !important;
}

.header1-block li a {
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 16px !important;
}
.header1-block li a:hover {
  color: #1976d2 !important;
  font-weight: 600;
}
.header1-block li {
  display: block;
  text-decoration: none;
}

.header1-block li,
.header1-block li:after,
.header1-block li:before {
  transition: all 0.5s;
}

/* stroke */
.header1-block.stroke li,
.header1-block.fill li {
  position: relative;
  cursor: pointer;
}

.header1-block.stroke li:after,
.header1-block.stroke li:after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 0%;
  content: "";
  /* background: #1976d2 !important; */
  height: 1.6px;
}

.header1-block.stroke li:hover:after {
  width: 100%;
}

.header1-block.fill li {
  transition: all 2s;
}

.header1-block.fill li:after {
  text-align: left;
  content: "";
  margin: 0;
  opacity: 0;
}

.header1-block.fill li:hover {
  /* color: #1976d2; */
  z-index: 1;
}

.header1-block.fill li:hover:after {
  z-index: -10;
  animation: fill 1s forwards;
  -webkit-animation: fill 1s forwards;
  -moz-animation: fill 1s forwards;
  opacity: 1;
}
.socialicons a img {
  width: 20px;
  height: 20px;
  transition: all 0.5s;
}
.socialicons a img:hover {
  width: 30px;
  height: 30px;
}
body::-webkit-scrollbar {
  display: none;
}

.basic-drawer-buttons {
  display: flex;
  justify-content: space-between;
  padding-left: 30px;
  padding-right: 34px;
  padding-top: 12px;
  padding-bottom: 15px;
}
.drawer-title {
  font-weight: 400;
  font-size: 23px;
}
.drawer-buttons {
  gap: 10px;
  display: flex;
  margin-top: 1vh;
  padding-left: 30px;
}
.drawer-field-1 {
  display: flex;
  justify-content: center;
}
.basic-drawer-divider {
  border: 0.5px gray solid;
  opacity: 25%;
}
.professional-fields-handle {
  display: flex;
  height: 88vh;
  justify-content: space-between;
  flex-direction: column;
}

.basic-fields-handle {
  display: flex;
  height: 88vh;
  justify-content: space-between;
  flex-direction: column;
}
.jobeze-change-header {
  display: flex;
  padding-left: 10% !important;
  gap: 10px;
  align-items: center;
  padding: 1%;
  border-bottom: 1px solid var(--black-018, rgba(0, 0, 0, 0.18));
  background-color: white;
}
.basic-jobeze-change {
  font-size: 23px;
  font-weight: 600;
}
.dotback {
  background-color: #f6f6f6;
  height: 90vh;
}
.basic-changepassword-block {
  background-color: white;
  height: 383px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 23px;
  padding-left: 24px;
  padding-top: 24px;
}
.basic-drawer-divider-1 {
  border: 1px gray solid;
  opacity: 25%;
}
.basic-drawer-divider-2 {
  border: 1px gray solid;
  opacity: 25%;
  margin-top: 32vh;
}
.other-drawer-divider-2 {
  border: 1px gray solid;
  opacity: 25%;
}
.professional-drawer-inputs {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  gap: 16px;
}
.drop-container {
  width: 100%;
}
.zxcvb {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  width: 113%;
}
.basic-scroll {
  height: 82vh;
  overflow-y: scroll;
}
.basic-scroll::-webkit-scrollbar {
  width: 7.5px;
}

.basic-scroll::-webkit-scrollbar-track {
  background: transparent;
}

.basic-scroll::-webkit-scrollbar-thumb {
  background-color: #006cb7;
  border-radius: 20px;
}
@media only screen and (max-width: 1400px) {
  .dropdownclassesfilter {
    /* width: 170px; */
    margin-left: 0;
  }
}
@media only screen and (max-width: 1200px) {
  .dropdownclassesfilter {
    margin-left: 15px;
  }
}

.revoke-box {
  width: "4px";
}
.child-other {
  display: grid;
  grid-template-columns: repeat(auto-fill, 210px) 58%;
}

.parent-other {
  margin-left: 16px;
}

.childDiv {
  color: var(--black-06, rgba(0, 0, 0, 0.6));
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
}
.vbn {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
}

.wrapper {
  display: inline-flex;
  list-style: none;
}

.wrapper .icon {
  position: relative;
  background: #ffffff;
  border-radius: 50%;
  padding: 15px;
  margin: 10px;
  width: 50px;
  height: 50px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .tooltip {
  position: absolute;
  top: 0;
  font-size: 14px;
  background: #ffffff;
  color: #ffffff;
  padding: 5px 8px;
  border-radius: 5px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .tooltip::before {
  position: absolute;
  content: "";
  height: 8px;
  width: 8px;
  background: #ffffff;
  bottom: -3px;
  left: 50%;
  transform: translate(-50%) rotate(45deg);
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .icon:hover .tooltip {
  top: -45px;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.wrapper .icon:hover span,
.wrapper .icon:hover .tooltip {
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
}

.wrapper .facebook:hover,
.wrapper .facebook:hover .tooltip,
.wrapper .facebook:hover .tooltip::before {
  background: #1877f2;
  color: #ffffff;
}

.wrapper .twitter:hover,
.wrapper .twitter:hover .tooltip,
.wrapper .twitter:hover .tooltip::before {
  background: #1da1f2;
  color: #ffffff;
}

.wrapper .instagram:hover,
.wrapper .instagram:hover .tooltip,
.wrapper .instagram:hover .tooltip::before {
  background: #e4405f;
  color: #ffffff;
}

.wrapper .Linkedin:hover,
.wrapper .Linkedin:hover .tooltip,
.wrapper .Linkedin:hover .tooltip::before {
  background: #1877f2;
  color: #ffffff;
}

.hoveringeffect a {
  display: block;
  text-decoration: none;
}

.hoveringeffect:hover > .submenu {
  display: block;
  /* animation: slideUp 1s ease-in-out; */
}
@keyframes slideUp {
  0% {
    transform: translateY(25%);
    visibility: visible;
  }

  100% {
    transform: translateY(0);
    visibility: visible;
  }
}
@keyframes slideDown {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(25%);
  }
}

.slideDown {
  animation: slideDown 0.5s ease-in-out backwards;
}
.slideUp {
  animation: slideUp 0.5s ease-in-out;
}
.submenu {
  width: 880px;
  box-shadow: 0px 11px 15px 0px rgba(0, 0, 0, 0.2);
  display: none;
  margin-top: 2px;
  /* animation: slideDown 0.5s ease-in-out forwards; */
  position: absolute;
  background-color: white;
}

.submenu div {
  display: block;
}

.submenu li a {
  color: black;
  /* margin-left: -18px !important; */
}

.menubarjobHover ul {
  width: 200px;
}

.submenuLogin {
  width: 180px;
  box-shadow: 0px 11px 15px 0px rgba(0, 0, 0, 0.2);
  display: none;
  margin-top: 2px;
  position: absolute;
  background-color: white;
}

.submenuLogin div {
  display: block;
}

.submenuLogin li a {
  color: black;
  /* margin-left: -18px !important; */
}
.hoveringeffect:hover > .submenuLogin {
  display: block;
  /* animation: slideUp 1s ease-in-out; */
}
.loginHover b {
  background-color: #deeef7;
}
@media only screen and (min-width: 1700px) and (max-width: 6000px) {
  .Uptojobs {
    font-size: 24px !important;
  }
  .staticSavedJobs {
    margin-left: -45px;
  }
  .appliedstatus {
    display: none;
  }
  .links-section {
    display: flex;
    column-gap: 9%;
    /* column-gap: 114px !important; */
  }
  .links-section1 {
    display: flex;
    column-gap: 12% !important;
  }
  .jobmobilemenu {
    display: none !important;
  }
  .SearchiconMobile {
    display: none !important;
  }
  .mobileprofilemenudisplay {
    display: none !important;
  }
  .logoinMobile {
    display: none !important;
  }
  .footerMainSection {
    padding: 42px 0px 72px 0px;
  }
}

@media only screen and (min-width: 1200px) {
  .SearchiconMobile {
    display: none !important;
  }
}

@media only screen and (min-width: 854px) and (max-width: 1900px) {
  .Uptojobs {
    font-size: 24px !important;
  }
  .staticSavedJobs {
    margin-left: -45px;
  }
  .appliedstatus {
    display: none;
  }
  .links-section1 {
    display: flex;
    column-gap: 10% !important;
  }
  .links-section {
    display: flex;
    column-gap: 6.5%;
  }
  .logoinMobile {
    display: none;
  }
  .Subscribing {
    margin-left: 114px;
    font-size: 14px;
  }
  .jobApplyDiv {
    position: absolute;
  }
  .jobmobilemenu {
    display: none !important;
  }
  .mobileprofilemenudisplay {
    display: none !important;
  }
  .footerMainSection {
    padding: 72px 0px 72px 0px;
  }
}
@media only screen and (min-width: 1901px) {
  .jobApplyDiv {
    position: absolute;
    left: 36%;
  }
}
.boxshadowsearch {
  box-shadow: 0px 11px 49px 0px rgba(0, 0, 0, 0.08);
  border: 1px solid rgba(0, 0, 0, 0.06);
}

@media only screen and (min-width: 854px) and (max-width: 991px) {
  .Uptojobs {
    font-size: 16px;
  }
  .staticSavedJobs {
    margin-left: -45px;
  }
  .navingBar {
    width: 98%;
  }
  .homingFlex {
    flex-direction: column-reverse;
  }
  .Subscribing {
    margin-left: 0px;
  }
}
@media only screen and (min-width: 767px) {
  .appliedstatus {
    display: none;
  }
}
.appliedstatus {
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  font-weight: 600;
}
@media (max-width: 767px) {
  .Toastify__toast {
    font-size: 15px !important;
    border-radius: 6px !important;
    font-weight: 800px !important;
    font-family: "Roboto", sans-serif !important;
  }
  .Toastify__toast-container {
    height: 25px !important;
    margin-left: 15% !important;
    margin-top: 16% !important;
    width: 75% !important;
    padding: 0 !important;
  }
  .Toastify__toast-body {
    padding: 0 !important;
  }
}

@media only screen and (min-width: 300px) and (max-width: 853px) {
  .footer-end-col1 {
    padding-bottom: 10px;
  }
  .socialicons {
    padding-bottom: 25px;
  }
  .links-section1 {
    display: grid;
    grid-template-columns: auto auto !important;
    padding: 0 !important;
  }
  .links-section {
    display: grid;
    grid-template-columns: auto auto;
    padding: 0 25px 0 25px;
  }
  .logoinDesktop {
    display: none;
  }
  .logoinMobile {
    padding-bottom: 25px;
    width: 120px;
  }
  .footerMainSection {
    padding: 40px 0 30px 0;
  }
  .profilemenudisplay {
    display: none !important;
  }
  .HeaderSearchbar {
    display: none !important;
  }
  .jobApplyDiv {
    position: unset !important;
  }
  .navingBar {
    width: 95%;
  }
  .active {
    width: fit-content;
  }
  .homingFlex {
    flex-direction: column-reverse;
  }
  .homepageAllimg {
    text-align: center;
  }
  .mobisearch {
    /* display: none; */
    width: 100%;
    /* overflow-x: scroll; */
    overflow: hidden;
    background: transparent;
  }
  .boxshadowsearch {
    box-shadow: none;
    border: 1px solid gray;
  }
  .mobilocation {
    display: none !important;
  }
  .hoveringeffect {
    display: none !important;
  }
  .jobmobilemenu {
    display: block;
  }
}

/* .menubarjobHover ul li {
  padding: 10px 18px;
  transition: all 0.2s !important;
}

.menubarjobHover ul li:hover {
  background-color: #deeef7;
  font-size: 17px !important;
  border-radius: 8px;
  color: #1A1B41 !important;
} */

.colorscc li a {
  font-size: 14px !important;
  padding: 10px 18px !important;
  transform: all 2s !important;
}

.colorscc li a:hover {
  background-color: #deeef7;
  border-radius: 8px;
  color: #1a1b41 !important;
}

.hover-new:hover {
  background-color: #eee;
}

.forgetpswrdwidth {
  width: 40%;
}
.Uptojobs {
  font-size: 13px;
}

.saved-title:hover {
  text-decoration: underline;
  color: #006cb7;
}

.css-heg063-MuiTabs-flexContainer {
  display: flex;
  /* justify-content: center; */
}

.css-pdct74-MuiTablePagination-selectLabel {
  display: none !important;
}
.css-16c50h-MuiInputBase-root-MuiTablePagination-select {
  display: none !important;
}
.css-levciy-MuiTablePagination-displayedRows {
  display: none !important;
}

.hoverstates {
  transition: 0.2s !important;
}

.hoverstates:hover {
  box-shadow: 0px 5px 5px 5px rgba(0, 0, 0, 0.1);
}

.css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input {
  font-weight: 800 !important;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  font-weight: 800 !important;
}
/* Slider.css */
.slick-prev:before {
  color: rgb(70, 69, 69) !important;
}
.slick-next:before {
  color: rgb(70, 69, 69) !important;
}

/* CV html Container  */
.generatebtn {
  background-color: rgb(244, 235, 255) !important;
  color: rgb(156, 123, 227) !important;
}
.generatebtn:hover {
  background-color: rgb(244, 235, 255) !important;
  color: rgb(156, 123, 227) !important;
}
.ql-toolbar {
  border-radius: 8px 8px 0px 0px;
}
.ql-container {
  border-radius: 0px 0px 8px 8px;
}
.ql-editor {
  height: 200px !important;
}
.ql-editor::-webkit-scrollbar {
  width: 6px;
}
.ql-editor::-webkit-scrollbar-thumb {
  background: #006cb7;
  border-radius: 4px;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.jbscrolling {
  overflow-y: scroll;
}
.jbscrolling::-webkit-scrollbar {
  width: 5px;
}

.jbscrolling::-webkit-scrollbar-track {
  background: transparent;
}

.jbscrolling::-webkit-scrollbar-thumb {
  background-color: #006cb7;
  border-radius: 20px;
}

.sampleLoading {
  background: url("../public/spinner1s.gif") no-repeat center center;
  height: 100%;
  width: 100%;
  z-index: 9999999;
}

.nav-item {
  text-wrap: nowrap;
}
