@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

  
  .skillspage{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(246, 246, 246);
    height: 101.7vh;
  }

  .phscreen {
    display:  none;
  }

.section-skills{
  width:47%;
  border: 1px solid black;
  margin-top:-5%;
  display: flex;
  flex-direction: column;
  padding: 3% 2%;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.3); 

}

.heading1-skills{
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 38px;
  font-family: 'Roboto', sans-serif;
 
}

.heading2-skills{
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 21px;
  font-family: 'Roboto', sans-serif;
  margin-top: 1.8%;
}

.input1-skills{
  padding-top:12px;
  padding-bottom:50px;
  padding-left: 17px;
  border: 1px solid #D9D9D9;
  margin-top: 4.8%;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  font-family: 'Roboto', sans-serif;
}

.heading3-skills{
  color: rgba(0, 0, 0, 0.6);
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  font-family: 'Roboto', sans-serif;
  margin-top: 1.8%;

}

.skills-button-skills{
  display: flex;
  justify-content: flex-end;
  column-gap: 40px;
  margin-top: -20px;
}

.button-skip-skills{
  width: 18%;
 display: flex;
 justify-content: center;
 margin-top:6%;
 background-color:#006CB7;
 font-family: 'Roboto', sans-serif;
 float: right;
 font-style: normal;
 font-weight: 600;
 font-size: 20px;
 line-height: 25px; 
 color:white;
 border-radius: 4px;
 padding: 2%;
 border:none
 
 
}

.button-back-skills{
  width: 18%;
  display: flex;
  justify-content: center;
  margin-top:6%;
  font-family: 'Roboto', sans-serif;
  float: right;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px; 
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.3);
  color: #006CB7;
  border-radius: 4px;
  padding: 2%;
 
}
.skipNav {
  position: absolute;
  padding-left: 39%;
  font-size: 17px;
  font-weight: 500;
  cursor: pointer;
  margin-top: 0.5%;
}

@media only screen and (max-width: 450px){
  .phscreen {
    display: block;
  }
  .skillspage {
    display:  none;
  }

  .phone-skill-title{
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 38px;
    font-family: 'Roboto', sans-serif;
    margin-left: 16px;
    margin-top: 42px;
    color: var(--black-087, rgba(0, 0, 0, 0.87));
  }
  .phone-skill-sub-heading{
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    margin-left: 16px;
    line-height: 21px;
    margin-top: 8px;
    color: var(--black-06, rgba(0, 0, 0, 0.60));
    font-family: 'Roboto', sans-serif;
  }

  .phone-skills-chip{
    padding-left: 16px;
    padding-right: 16px;
  }

  .skills-button-skills{
    display: flex;
    justify-content: flex-end;
    column-gap: 16px;
    margin-top: 370px;
    margin-right: 16px;
   margin-bottom: 25px;
  }
}