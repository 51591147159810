@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

.viewdetailpage{
    height: 100vh;
    background-color: whitesmoke;
    
}


.detail-1-div{
    margin-left: 8.2%;
}
.detail-tittle{
color: #000000;
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 28px;
font-family: 'Roboto', sans-serif;
}