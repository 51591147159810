.pb-1.fx{
    display: flex;
    justify-content: end;
}
.box-container{
display: flex;
justify-content: end;
gap: 8px;
width: 131px
}
.share-text{
    font-size: 14px;
    font-family: Roboto;
    font-weight: 400;
    width: 93px;
    padding: 1.5px;
    color: #424242;
}
.share-icon{
    width: 160px;
    /* 
    height: 160px; */
    /* background-color: aqua; */
    /* size: 20px; */
    cursor: pointer;
    color: #3F95EA
}
.small-icon{
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
}

@media screen  and (max-width: 700px) {
    /* .box-container{
        display: flex;
        flex-direction: column;
        justify-content: end;
        align-items: center;
    } */
    .share-text{
        font-size: 11px;
    }
    .share-icon{
        height: 17px !important;
    }
}