
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');


.viewdetail-section1{
    border: 1px solid #006CB7;
    box-shadow: 5px 4px 4px 2px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    width: 283px;
    background-color: white;
    margin-left: 122px;
    margin-top: 2%;
}
.detail-section-title{
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    font-family: 'Roboto', sans-serif;
    color:  #000000;
    margin-left: 20px;
        margin-top: 13.3px;
}
.detail-block{
    width:30px;
    height:26.29px;
    background-color:#D9D9D9;
    margin-left: 20px;
    
    margin-top: -4px;
}

.viewdetail-title{
font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;  
    color: gray;
}