@font-face {
    font-family: 'Roboto', sans-serif;
    src: url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap') format('truetype');
  }

.sign-form{
    height:auto;
    width:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
}
.sign-Header{
    border-bottom: 2px solid rgba(0, 0, 0, 0.3);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); 
    height:7.4%;
    width:100%
}

.sign-Menu{
    margin-left:40px;
    padding:0.8%;
}

.sign-button0{
    background-color:#006CB7;
    color:white;
    Width:7%;
    Height:4.4%;
    border-radius: 4px;
    margin-right:50px;
    border:solid transparent 2px;
   
   font-style: normal;
   font-weight: 500;
   font-size: 21px;
   font-family: 'Roboto', sans-serif;
    
}

.sign-button0:hover{
    outline:none;
    border:solid #0096FF 2px;
}

.sign-Menu{
    display:flex;
    align-items: center;
    justify-content: space-between;
}

.sign-tittle p{
    text-align: center;
    margin-top:3%;
    margin-bottom:3%;
    font-style: normal;
    font-weight:700;
    font-size:24px;
    line-height: 45px;
    font-family: 'Roboto', sans-serif;
}

.sign-text1{
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size:16px;
    font-family: 'Roboto', sans-serif;
    margin-bottom: 0%;
}
.sign-Loginpage{
    width:25%;
    box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.16);
    border-radius: 15px;
    margin-top:3.5%;
}


.sign-user-box{
    display: flex;
    flex-direction: column;
    gap:12px;
    padding-left: 9%;
    padding-right:9%;
    padding-bottom:7%;
}

.sign-name{
    padding:3%;
    background-color: #EBEBEB;
    border: none;
    border-radius: 4px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
}

.sign-name:focus-visible{
    outline:none;
    border:solid #0096FF 2px;
}


.sign-email{
    padding: 3%;
    background-color: #EBEBEB;
    border: none;
    border-radius: 4px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
}

.sign-email:focus-visible{
    outline:none;
    border:solid #0096FF 2px;
}

.sign-password{
    padding:3%;
    background-color: #EBEBEB;
    border: none;
    border-radius: 4px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size:16px;
    
}

.sign-password:focus-visible{
    outline:none;
    border:solid #0096FF 2px;
}


.sign-button2{
    /* background-color:#2D65BC; */
    /* color: white; */
    padding:3%;
    /* border-radius: 4px; */
    /* border:solid transparent 2px; */
    font-family: 'Roboto', sans-serif;
    /* font-style: normal; */
    font-weight: 600;
    /* font-size:16px; */
    /* margin-top:1%; */

    
    
}

.sign-button2:hover{
    /* outline:none; */
    /* border:solid #0096FF 2px; */
}

.sign-pipline{
    padding-top: 2px !important;
    padding:3%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.sign-line{
    height:2px;
    width:42%

}


.sign-button3{
     border-radius: 4px;
     border:none;
     color: #2D65BC;
     background-color: white;
     padding:3%;
     border: 1px solid rgba(45, 101, 188, 0.3);
     border-radius: 4px;
     font-family: 'Roboto', sans-serif;
     font-style: normal;
     font-weight: 500;
     font-size:16px;
     
}

.sign-button3:hover
{
    outline:none;
    border:solid #0096FF 2px;
}



.sign-user-box1{
    display:flex;
    margin-left:-20px;
    gap:15px;
}

.sign-text2{
    color:#000000;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size:16px;
    margin-top:28px;
}

.sign-button4{
    width: 100px;
    height:40px;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    margin-top:20% !important;
}


