@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

.update-resume-box{
    /* width: 96%; */
    /* height: 86px; */
    border: 1px solid #D9D9D9;
    border-radius: 4px;
    /* display: flex; */
    /* align-items: center; */
    /* justify-content: space-between; */
    /* background: #FFF; */
    /* margin-left: auto; */
    /* margin-right: auto; */
}

.button-delete{
    border:none;
    color:#000000;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    background-color: white;
    
}

.button-replace{
    background-color: white;
    border:none;
    color:#006CB7;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
}

.resume-cv{
font-weight: 600;
font-size: 16px;
line-height: 19px;}



.resume-date{
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: rgba(0, 0, 0, 0.6)}

