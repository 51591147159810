@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

.aboutMain1 {
    background-color: #F2F9FD;
    overflow: hidden;
}

.AboutSection1{
    font-weight: 500;
}
 
.AboutSection1-1{
    color: rgba(0, 0, 0, 0.87);
    /* font-weight: 600; */
}

.AboutSection1-6{
    padding: 0 100px;
}

.AboutSection2{
    color: rgba(0, 0, 0, 0.87);
    font-weight: 600;
}

.AboutSection3{
    font-size: 23px;
    width: 62%;
    margin: auto;
}

@media only screen and (min-width: 300px) and (max-width: 853px){
    .AboutSection1{
        font-size: 23px;
    }
    .AboutSection1-1{
        font-size: 16px;
        font-weight: 500;
    }
    .AboutSection3{
        width: 100%;
    }
    .AboutSection1-6{
        padding: 0 !important;
    }
}

.iconsgap a{
    margin: 0 8px;
}

.newBgcolor{
    background-color: rgba(226, 243, 255, 1);
}

.contactusImage{
  background-image: url("../images/contactusBg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  position: relative;
  z-index: 10;
  backdrop-filter: blur(5px);
}
.contactusImage::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../images/contactusBg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  filter: blur(5px); /* Adjust the blur radius as needed */
  z-index: -1;
}
    
.Our-section-text {
    margin-left: 0;
    /* default for xs */
}

/* Media queries */
@media (min-width: 600px) {
    .Our-section-text {
        margin-left: 0px;
        /* from 600px to 1200px */
    }
                                        
}

@media (min-width: 1200px) and (max-width: 1600px) {
    .Our-section-text {
        margin-left: 90px;
        /* from 1200px to 1600px */
    }
}

@media (min-width: 1600px) {
    .Our-section-text {
        margin-left: 100px;
        /* greater than 1600px */
    }
}