@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

.basic-page {
    background-color: #FFFFFF;
    ;
    box-shadow: 5px 4px 4px 2px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    width: 85%;
    /* padding-bottom:3%; */
    /* margin-top:56px; */
}

.basic-heading1 {
    font-weight: 600;
    font-family: 'Roboto', sans-serif;
    font-size: 24px;
    padding: 1%;
}

.field-3 input {
    background-color: #EBEBEB;
    ;
    padding: 1.5%;
}

.basic-page-div {
    display: flex;
    flex-direction: column;
    gap: 2.8vh;
    padding-left: 2.5%;
}

.BasicHr {
    border: 1px solid darkgrey;
    margin-top: -28px;
    padding-left: -74px;
    margin-left: -23px;
}

.basic-1-button {
    /* background-color: #006CB7; */
    /* color: white; */
    /* border: none; */
    padding: 1%;
    /* border-radius: 4px; */
    /* font-style: normal; */
    /* font-weight: 600; */
    /* font-size: 17px; */
    /* line-height: 19px; */
    font-family: 'Roboto', sans-serif;
    /* width: 110px; */
    /* height: 44px; */

}

.basic-2-button {
    color: #006CB7;
    background-color: white;
    border: none;
    border: 1px solid rgba(0, 108, 183, 0.4);
    border-radius: 4px;
    padding: 1%;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 19px;
    font-family: 'Roboto', sans-serif;
    width: 110px;
    height: 44px;
    margin-left: 10px;
}

.basic-buttons {
    margin-top: 10.8%;
    padding-left: 2.5%;
}

/* textarea {
    resize: none;
    width: 325px;
    height: 140px;
    font-weight: 500;
    outline: none;
    border-radius: 8px;
    border-color: lightgrey;
} */
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.custom-input {
    width: 250px;
}

.custom-input::placeholder {
    width: 300px;
}

.location-input {
    width: 110px;
}

.jobeze-progress-profile {
    width: 85%;
    background-color: #FFFFFF;
    border-radius: 4px;
    box-shadow: 5px 4px 4px 2px rgba(0, 0, 0, 0.05);
    padding-top: 16px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px;
    display: flex;
    justify-content: space-between
}

.jobeze-progress-div1 {
    display: flex;
    align-items: center;
    gap: 16px;
}

.jobeze-progress-status {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: var(--black-087, rgba(0, 0, 0, 0.87));
    font-weight: 600;
}


.jobeze-basic-div {
    /* margin-top: 24px; */
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 24px;
    padding-right: 24px;
    display: flex;
    justify-content: space-between
}

.jobeze-basic-details {
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.25px;
    font-weight: 600;

}

.jobeze-details-block {
    display: flex;
    gap: 48px;
    padding-left: 24px;
    padding-bottom: 24px;

}

.jobeze-candidate-details {
    width: 18%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    color: var(--black-06, rgba(0, 0, 0, 0.60));
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
}

.jobeze-candidate-filled-details {
    display: flex;
    flex-direction: column;
    gap: 16px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
}

.jobeze-change-pass {
    margin-top: 24px;
    width: 85%;
    padding: 2%;
    display: flex;
    justify-content: space-between;
    /* margin-bottom: 33px; */
    background-color: #FFFFFF;
    border-radius: 4px;
    box-shadow: 5px 4px 4px 2px rgba(0, 0, 0, 0.05);
}

.jobeze-change-heading {
    font-size: 18px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.25px;
}
.jobeze-contact-details-delete {
    width: 650px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
}

.fyi {
    width: 30rem;
    word-break: break-all;
}

.edit-drag {
    display: none;
}

.web-edit {
    display: block;
}

.hover-effect:hover {
    background-color: #EFFFFF
        /* Change the background color to the desired hover effect */
}

/* .break-words{
    width: unset;
    white-space: unset;
    overflow: unset;
    text-overflow: unset;
} */

.jobeze-change-pass1 {
    display: none;
}

.basic-dob {
    width: 360px;
}

.change-web-pass {
    width: 85%;
    border-radius: 4px;
    box-shadow: 5px 4px 4px 2px rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
}

.change-mobile {
    display: none;
}

@media only screen and (max-width: 450px) {
    .basic-page {
        background-color: #FFFFFF;
        box-shadow: unset;
        border-radius: unset;
        width: unset;
        padding-bottom: unset;
        margin-top: unset
    }

    .forgetpswrdwidth {
        width: 100% !important;
    }

    /* .borderInput{
        margin-left: unset !important;
    } */

    .verify-hide {
        display: none !important;
    }

    .input1-upload {
        padding: unset !important;
        padding: unset !important;
        margin-top: 4%;
        width: 25%;
        margin-left: 4%;
    }


    .borderInput {
        display: unset !important;
    }

    .labelH {
        text-align: center;
        margin-top: 2.5%;
    }

    .jobeze-details-block {
        gap: unset !important;
        padding-left: unset !important;
        justify-content: space-around !important
    }

    .css-19pujn9 {
        width: 94% !important;
    }

    .change-web {
        display: none;
    }

    .change-mobile {
        display: Block;
    }

    .change-web-pass {
        width: unset;
        border-radius: unset;
        box-shadow: unset;
        box-sizing: unset;
    }

    .edit-photo {
        border-radius: 50%;
        background-color: #006CB7;
        height: 40px;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .drop-title2 {
        display: none;
    }



    .inputProfile-upload {
        opacity: 0;
        margin-left: -89px !important;
        margin-top: 11px !important;
        margin-bottom: 12px !important
    }

    .UploadBox {
        width: 22% !important;
        padding: 1.3% !important;
        font-size: 17px !important;
        margin-left: 30% !important;
        -webkit-appearance: none;
    }

    /* .borderInput {
        display: unset !important;
        margin-left: unset !important;
    } */
    .other-viewmode {
        width: unset !important;
        margin-top: unset !important;
        box-shadow: unset !important;
        background-color: unset !important;
        border-radius: unset !important;
    }

    /* 
    .child-other {
       
        grid-template-columns: repeat(auto-fill, 154px) 45% !important;
    } */

    .portfolioWidth {
        width: unset !important;
    }

    .pre-div {
        width: unset;
    }

    /* 
    .update-resume-box {
        width: unset;
    } */

    .resume-block-candiate {
        width: unset;
        margin-left: 16px;
        margin-right: 16px;
        height: unset;
    }

    .update-resume-box {
        padding: 3% !important;
        height: unset !important;
    }

    .ppp {
        width: 44%;
        text-align: end;
    }

    .jobeze-details-block-p {
        gap: unset !important;
        padding-left: 16px !important;
        justify-content: space-around;
        padding-right: 16px !important;
    }

    .jobeze-candidate-details-p {
        width: 49% !important;
    }

    .edit-drag {
        display: block;
    }

    .web-edit {
        display: none;
    }

    .css-19kzrtu {
        padding: unset !important;
    }

    .fyi {
        width: unset
    }

    



    .jobeze-candidate-filled-details {
        text-align: end;
        /* padding-right: 16px; */

    }

    .jobeze-candidate-details {
        width: unset
    }

    .break-words {
        width: 161px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .break-words-p {
        width: 161px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .break-words-o {
        width: 181px !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }



    .jobeze-progress-profile {
        width: 94%
    }

    .new-circle {
        margin-left: unset;
        margin-right: unset;
    }

    .jobeze-change-pass {
        display: none;
    }

    .jobeze-change-pass1 {
        margin-top: 24px;
        padding: 5%;
        display: flex;
        justify-content: space-between;
        /* margin-bottom: 33px; */
        background-color: #FFFFFF;
        border-radius: 4px;
        box-shadow: 5px 4px 4px 2px rgba(0, 0, 0, 0.05);
        border-top: 1px gray solid
    }
}

@media only screen and (max-width: 361px) {
    .jobeze-details-block {
        gap: 24px;
    }

    .css-1bmsl3s {
        gap: 8px !important;
    }

    .jobeze-details-block {
        margin-left: 7px !important;
        margin-right: 7px !important;
    }

    .break-words-o {
        width: 175px !important
    }

}

@media only screen and (min-width: 395px) and (max-width: 415px) {
    .jobeze-details-block {
        gap: 66px;
    }
}


/* @media only screen and (min-width: 350px)  and (max-width: 390px) {
    .basic-drawer-buttons{
        margin-left: 52px;
    } */

/* .basic-fields-handle{
        margin-left: 52px;
    } */

/* .basic-dob{
        width: 318px;
    }
  } */

/* 
  @media only screen and (min-width: 390px)  and (max-width: 400px) {
    .basic-drawer-buttons{
        margin-left: 25px;
    }

    .basic-fields-handle{
        margin-left: 23px;
    }

    .basic-dob{
        width: 341px;
    }
  } */


@media only screen and (min-width: 320px) and (max-width: 450px) {
    .profile-page-jobeze-heading {
        padding-left: 4%;
        gap: 20px;
    }

    .jobeze-progress-profile {
        box-shadow:
            5px 4px 4px 2px rgba(0, 0, 0, 0.05),
            /* Original box shadow */
            -5px 0 0 rgba(0, 0, 0, 0.05),
            /* Left side */
            0 -4px 0 rgba(0, 0, 0, 0.05),
            /* Top side */
            0 4px 0 rgba(0, 0, 0, 0.05)
    }
}

/* @media only screen and (min-width: 370px)  and (max-width: 380px) {
    .jobeze-details-block{
        gap: 38px !important;
    }
  } */


.buttonhold {
    margin-top: 29vh !important;
}

/* @media only screen and (min-width: 375px) {
    .change-mobile-handle{
       margin-right: 20px !important;
    }

  } */

@media only screen and (max-width: 360px) {

    .child-other {

        grid-template-columns: repeat(auto-fill, 154px) 45% !important;
    }
}

@media only screen and (max-width: 390px) {

    .child-other {

        grid-template-columns: repeat(auto-fill, 181px) 45% !important;
    }
}

@media only screen and (max-width: 412px) {

    .child-other {

        grid-template-columns: repeat(auto-fill, 185px) 45% !important;
    }

    .css-1bmsl3s {
        gap: 22px !important
    }
}

@media only screen and (max-width: 376px) {

    .child-other {

        grid-template-columns: repeat(auto-fill, 150px) 51% !important;
    }
}


@media only screen and (min-width: 768px) and (max-width: 1200px) {

    .section-upload {
     margin-top: 25% !important;
     width: 70% !important
     
    }

    .section-skills{
        width: 70% !important;
       
    }

    .labelBox{
        margin: 2.2% !important;
        width: 13% !important;
    }

    .jobeze-candidate-details{
        width: 30% !important;
    }

    .jobeze-details-block{
        gap:20px !important
    }

    .jobeze-candidate-filled-details {
        width: 70% !important;
    }

    .profile-2-circle{
        width: 100% !important;
    }

    .profile-2-details{
        width: 100% !important;
    }
    .Profile-2-box{
        margin-left: 12% !important;
    }

    .basic-page {
        width: 94%;
    }

    .jobeze-progress-profile {
        width: 94%;
    }

    .jobeze-change-pass{
        width: 94%;
    }

    .resume-block-candiate{
        width: 94%;
    }

    .jobeze-candidate-details-p{
        width: 36% !important;
    }

    .pre-div{
        width:209px !important
    }

    .fyi{
        width: 17rem !important;
    }

    .break-words-p{
        width: 227px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .break-words-o {
        width: 194px !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .other-viewmode {
        width: 94% !important;
    }

    .change-web-pass{
        width: 94% !important;
    }

    .jobeze-details-block-p {
        gap:22px !important
    }

    .forgetpswrdwidth {
        width: 70%;
    }   

    .css-ve3j7o{
        margin-left: 2rem !important;
        margin-right: 2rem !important;
    }
    .break-words {
        width: 208px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}