@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

.Profile-section{
    display: flex;
    background-color:whitesmoke;
   /* padding-bottom: 4vh; */
   /* height: 92vh; */
   /* overflow: overlay; */
   padding-top: 24px
}
.Profile-1-box{

  flex: 30%;
}

.profile-1-div{
    margin-left:29%;
    gap: 5%;
    margin-top:4%;
}

.Profile1-tittle{
    color: #000000;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    font-family: 'Roboto', sans-serif;
}

.arrowimg{
    height: 14px;
    width: 10px;
    margin-top: 8px;
    cursor: pointer;
}

.profile-2-circle{
    background-color: white;
    width: 60%;
    padding-bottom: 3%;
    margin-left: 29%;
    padding-top: 10px;
    /* box-shadow: 5px 4px 4px 2px rgba(0, 0, 0, 0.05); */
}

.new-profile-name{
    font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 19px;
font-family: 'Roboto', sans-serif;
color: #000000;
text-align:center;
    padding-top: 9px;
}


.new-profile-email{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    color: grey;
    width: 245px;
    margin: 5px auto ;
}

.new-circle{
    border-radius: 50%;
    height:135px;
    width: 135px;
    background-color: #D9D9D9;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    margin-top: 2%;

}
.profile-2-details{
    background-color:white;
    margin-top:4.3vh;
    width: 61%;
    margin-left:29%;
    display: flex;
    flex-direction: column;
    box-shadow: 5px 4px 4px 2px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    padding-bottom: 15%;
}
.Basic-Details-button-2{
/* background: rgba(255, 255, 255, 0.48); */
border: none;
/* box-shadow: 5px 4px 4px 2px rgba(0, 0, 0, 0.05); */
color: #000000;
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 19px;
font-family: 'Roboto', sans-serif;
padding: 7%;
/* margin-top: 30px; */
background: var(--custom-primary-012, rgba(25, 118, 210, 0.12));

}

.Basic-Details-button-1{
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    color: #000000;
    border: none;
    background-color: white;
    /* margin-top: 30px; */
    padding: 7%;
    font-size: 18px;
    line-height: 19px;

}

.Basic-Details-button-3{
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    color: #000000;
    border: none;
    background-color: white;
    border: none;
    background-color: white;
    padding: 7%;
    font-size: 18px;
    line-height: 19px;
}
.Basic-Details-button-4{
    /* background: rgba(255, 255, 255, 0.48); */
    background: var(--custom-primary-012, rgba(25, 118, 210, 0.12));
   box-shadow: 5px 4px 4px 2px rgba(0, 0, 0, 0.05);
   color: #000000;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 19px;
  font-family: 'Roboto', sans-serif;
  padding: 7%;
  border: none;
}

.Basic-Details-button-5{
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    color: #000000;
    border: none;
    background-color: white;
    border: none;
    background-color: white;
    padding: 7%;
    font-size: 18px;
    line-height: 19px;
}

.Basic-Details-button-6{
    background: var(--custom-primary-012, rgba(25, 118, 210, 0.12));
    border: none;
    box-shadow: 5px 4px 4px 2px rgba(0, 0, 0, 0.05);
    color: #000000;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 19px;
    font-family: 'Roboto', sans-serif;
    padding: 7%;
}

.Profile-2-box{
    background-color:whitesmoke;
    flex:70%;
  }

/* .profile-1-div{
    border-bottom: 1px solid var(--black-018, rgba(0, 0, 0, 0.18));

} */

.profile-page-jobeze-heading{
    border-bottom: 1px solid var(--black-018, rgba(0, 0, 0, 0.18));
    padding-top: 12px;
    display: flex;
    padding-left: 8%;
    gap:10px
    
}

.Professional-page{
    height: 330px;
    background-color: azure;
}

.resume-block-candiate{
    height: 167px;
    margin-bottom: 24px;
    border-radius: 4px;
    background: var(--shades-white, #FFF);
    width: 85%;
}

.Resume-heading{
    padding: 1%;
    font-size: 19px;
    line-height: 32px;
    letter-spacing: 0.25px;
    font-weight: 600;
    padding-left: 18px
}

  .new-circle {
    position: relative;
    display: inline-block;
    display: flex;
  }
  
  .avatar-label {
    position: absolute;
    top: 0;
    left: 0;
    width: 135px;
    height: 135px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 50%;
    transition: background-color 0.3s;
  }
  
  .avatar-label:hover {
    background-color: rgba(0, 0, 0, 0.5); /* Change the opacity or color as per your preference */
  }
  
  .avatar-overlay {
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  .avatar-text {
    position: absolute;
    bottom: 5px;
    left: 50%;
    transform: translateX(-50%);
    padding: 5px 10px;
    color: #fff; /* Set the text color to white or your desired color */
    font-weight: 600;
    font-size: 14px;
    border-radius: 20px;
    opacity: 0; /* Initially hidden */
    transition: opacity 0.3s;
  }
  
  .avatar-overlay:hover .avatar-text {
    opacity: 1; /* Show the text on hover */
  }
  
  .avatar-overlay:hover .uploaded-image {
    filter: blur(5px); /* Adjust the blur strength as per your preference */
  }

  .pre-div{
    width: 620px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.profile-mobile-page{
  display: none;
}

@media only screen and (max-width: 450px){
  .profile-mobile-page{
    display: block;
  }

  .hide-in-desktop{
    display: none;
  }
  .mobile-image{
    display: flex;
    justify-content: center;
}


.mobile-image-upload{
  display: flex;
  justify-content: center;
  align-self: center;
}

.avatar-label2 {
  /* position: unset; */
  width: 135px;
  height: 37px;
  display: unset;
  flex-direction: unset;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: unset;
  transition: unset;
  border-radius: 5px;
  color: #006CB7;
  font-weight: 600;
  font-size: 18px;
  position: absolute;
  margin-top: 27px;
 
  margin-right: 128px;
}

}

  