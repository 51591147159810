@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');
.other-page{
background-color: #FFFFFF;;
    box-shadow: 5px 4px 4px 2px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    width: 85%;
    /* padding-bottom: 2%; */
    margin-top:25px;

}


.other-heading1{
    font-weight: 600;
    font-family: 'Roboto', sans-serif;
    font-size: 24px;
    padding: 1%;
    padding-left: 23px;
}

.other-BasicHr{
    border: 1px solid darkgrey;
    margin-top: -10px;
}

.highest{
    background-color: #EBEBEB;;
    padding:1%;
    border:none;
    border-radius: 4px;
    width:337px;
    margin-top: 20px;
    margin-left: 23px;
}
.other-buttons {
    /* box-sizing: border-box; */
    margin-top: 42vh;
    padding-left: 2.5%;
}
.other-1-button {
    background-color: #006CB7;
    color: white;
    border: none;
    padding: 1%;
    border-radius: 4px;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 19px;
    font-family: 'Roboto', sans-serif;
    width: 110px;
    height: 44px;
}

.other-viewmode{
    width: 85%;
    /* margin-top: 16px; */
    box-shadow: 5px 4px 4px 2px rgba(0, 0, 0, 0.05);
    background-color: white;
    border-radius: 4px;
}

.other-edit-mode-fields{
    display: flex;
    justify-content: center;
}
.portfolioWidth{
    /* width: 90%; */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}