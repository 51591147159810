.cookies-banner {
  font-family: "Roboto";
  z-index: 1000;
  background-color: rgba(15, 18, 25, 0.8);
  /* border: 1px solid #eaf1f5; */
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  /* line-height: 24px; */
  position: fixed;
  bottom: 0;
}

.cookies-banner span{
    color: #fff;
}

.acceptance-btn .acceptance-btn-text{
  display: flex;
  padding: 4px 12px;
  background: #1976d2;
  border-radius: 20px;
  margin-left: 20px;
  cursor: pointer;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #fff;
  border: none;
}

a{
  color: #4095e9;
  font-weight: 700
}

.cookiePolicy, .privacypolicy, a:focus, a:hover, a:visited {
  outline: none;
  text-decoration: none;
  color: #4095e9;
}
