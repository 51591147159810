@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');


  
  .Uploadpage{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f6f6f6

  }

  .phone-upload-page{
    display: none;
  }

.section-upload{
  width:40%;
  height: 34%;
  border: 1px solid black;
  margin-top: 6%;
  display: flex;
  flex-direction: column;
  padding:2% 2%;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.3); 

}

.heading1-upload{
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
 
}

.heading2-upload{
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height:19px;
  margin-top: -1%;
}

.input1-upload22 {
  /* opacity: 0; */
  padding: 4%;
  border: 1px solid #000000; 
}
.inputProfile-upload {
  opacity: 0;
  margin-left: -165px;
  margin-top: 35px;

}

.heading3-upload{
  color: rgba(0, 0, 0, 0.6);
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  margin-top: 1.8%;

}


.upload-button{
  display: flex;
  justify-content: flex-end;
  column-gap: 40px;
  margin-top: -20px;
}

.button-link-upload{
  width:26%;
  height: 48px;
  display: flex;
  justify-content: center;
  margin-top:4%;
  background-color:#006CB7;
  font-family: 'Roboto', sans-serif;
  float: right;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px; 
  color: #006CB7;
  background-color: white;
  border: 1px solid rgba(0, 108, 183, 0.4);
  border-radius: 4px;
  padding: 2%;
  
}
.button-next-upload {
  width: 16%;
  height:48px;
  display: flex;
  justify-content: center;
  margin-top:4%;
  background-color:#006CB7;
  font-family: 'Roboto', sans-serif;
  float: right;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px; 
  color: #FFFFFF;
  border-radius: 4px;
  padding: 2%;
  border:none;
}

.pipline-upload{
  display: flex;
  width: 78%;
  align-items: center;
  column-gap: 13px;
  margin-left:auto;
  margin-right: auto;
}

.upload-link{
  color:#000000;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  padding-top: 1%;
}
  
.pipline-upload img{
  height: 1.5px;
  width: 46%;
}

.upload-change-next{
  width: 86px;
  height: 39px;
  float: right;
  border: none;
  width: 15%;
  color: #FFFFFF;
  background: #006CB7;
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin-top: 5%;
}

.input1-upload2 {
  color: rgba(0, 0, 0, 0);
}

.labelBox {
  position: absolute;
  border: 1px solid;
  border-radius: 6px;
  padding:0.3%;
  background-color: lightgray;
  margin:1.2%;
  width: 7%;
  text-align-last: center;
  color: black;
  border: none;
}
.load-File{
  margin-top: 6%;
  margin-left: -35%;
}
.UploadBox {
  position: absolute;
  cursor: pointer !important;
  border-radius: 4px;
  padding:0.3%;
  margin: 2% 3.5%;
  width: 7%;
  font-size: 14px;
  text-align-last: center;
  color: #006CB7;
  border: 1px solid rgba(25, 118, 210, 0.50);
}
.borderInput {
  display: flex;
  column-gap:4%;
  margin-left:33%;
}
.labelH { 
  /* margin-top:5.7%; */
  font-weight: 500;
  font-size: initial;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 75%;
  margin: auto;
}
.borderInputMain {
  border: 2px dashed #D9D9D9;
}


@media only screen and (max-width: 450px){
  .phone-upload-page{
    display: block;
  }

  .upload-parent-div {
     display: none;
  }

  .heading1-upload{
  font-style: normal;
  font-family: 'Roboto', sans-serif;
  color: var(--black-087, rgba(0, 0, 0, 0.87));
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  margin-top: 42px;
  margin-left: 16px;
 }
 .heading2-upload{
  font-style: normal;
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  font-size: 18px;
  color: var(--black-06, rgba(0, 0, 0, 0.60));
  margin-top: 8px;
  margin-left: 16px;
  line-height: 24px;
}

.borderInputMain{
  margin-left: 16px;
  margin-right: 16px;
  /* margin-top: 24px; */
}

.labelBox{
  position: absolute;
    border: 1px solid;
    border-radius: 6px;
    background-color: lightgray;
    margin: 3.2%;
    width: 26%;
    text-align-last: center;
    color: black;
    border: none;
    padding-top: 5px;
    padding-left: 3px;
    padding-bottom: 5px;
    padding-right: 3px;
    /* margin-left: -7px; */
}

/* .borderInput{
  width:85%;
  margin-left:30%;
} */

.pipline-upload {
  width:85%
}
.upload-change-next {
  margin-right: 16px;
  margin-top: 100px;
  margin-bottom: 15px;
  width:23%
}

}