@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

.form{
    height:auto;
    width:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
}
.Header{
    border-bottom: 2px solid rgba(0, 0, 0, 0.3);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); 
    height:7.4%;
    width:100%
}

.Menu{
   width: 95%;
    padding:0.8%;
    margin-left: auto;
    margin-right: auto;
}

.button0{
    background-color:#006CB7;
    color:white;
    Width:7%;
    Height:4.4%;
    border-radius: 4px;
    border:solid transparent 2px;
   
   font-style: normal;
   font-weight: 500;
   font-size: 21px;
   font-family: 'Roboto', sans-serif;
    
}

.button0:hover{
    outline:none;
    border:solid #0096FF 2px;
}

.Menu{
    display:flex;
    align-items: center;
    justify-content: space-between
}

.tittle p{
    text-align: center;
    margin-top:6%;
    margin-bottom:6% ;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 45px;
    font-family: 'Roboto', sans-serif;

}
.text1{
    text-align: center;
    font-style: normal;
    font-weight:600;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    margin-top: 4.8%;
     color:#000000
} 
.Loginpage{
    width: 25%;
    box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.16);
    border-radius: 15px;
    margin-top:3.5%;
}

.user-box{
    display: flex;
    flex-direction: column;
    padding-left: 9%;
    padding-right:9%;
    padding-bottom:7%;
    gap:12px
   
}

.email{
    padding: 3%;
    background-color: #EBEBEB;
    border: none;
    border-radius: 4px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
}

.email:focus-visible{
    outline:none;
    border:solid #0096FF 2px;
}

.password{
    padding: 3%;
    background-color: #EBEBEB;
    border: none;
    border-radius: 4px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size:16px;
    
    
}

.password:focus-visible{
    outline:none;
    border:solid #0096FF 2px;
}

.button1{
    margin-top: -11px;
    /* color:#2D65BC; */
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    font-size: 15px;
    text-decoration: none;
}



.button2{
    font-family: 'Roboto', sans-serif;
    letter-spacing: 1px;
}


.pipline{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.line{
    height:2px;
    width:42%

}

.button3{
     border-radius: 4px;
     width: 130px;
     border:none;
     color: black;
     background-color: white;
     border: 1px solid rgba(45, 101, 188, 0.3);
     border-radius: 4px;
     font-family: 'Roboto', sans-serif;
     font-style: normal;
     font-weight:600;
     font-size:16px;
     margin-bottom:-1%;
}

.button3 img{
    width: 20%;
    padding-right: 7px;
    margin-top: -2px;
}

.button3:hover
{
    outline : 1px solid #0096FF !important;
    border:  1px solid #0096FF !important;
}
.button3-linkdin {
    border-radius: 4px;
     width: 130px;
     border:none;
     color: black;
     background-color: white;
     border: 1px solid rgba(45, 101, 188, 0.3);
     border-radius: 4px;
     font-family: 'Roboto', sans-serif;
     font-style: normal;
     font-weight:600;
     font-size:16px;
     margin-bottom:-1%;
}
.button3-linkdin img {
    width: 23px;
    padding-right: 7px;
    margin-top: -2px;
}
.button3-linkdin:hover {
    outline:none;
    border:solid #0096FF 1px;
}

.eye img{
    height: 13px;
    float: right;
    padding-top: -9px;
    margin-top: -39px;
    margin-right: 12px;
}


.Login-user-box1{
     display:flex;
     gap:10px;
     margin-top: -1.5%;
}

.text2{
    color:#000000;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight:600;
    font-size: 16px;
    margin-top: 64px;
}

.Loginwith{
    
    text-align: center;
    font-family: 'Roboto', sans-serif;
    color: #000000;
    font-style: normal;
    font-weight:600;
    font-size:16px;
    margin-top: -5%;
}

.button4{
    /* background-color:#2D65BC; */
    /* color:white; */
    width: 100px;
    height:40px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    margin-top: 55% !important;
    margin-left:10px
}

.appbox{
    display: flex;
    justify-content: center;
    column-gap:12.4%;
    margin-top:1%;
    height: 6.5vh;
}
    @media only screen and (min-width: 1550px) and (max-width: 1990px)  {
    .Loginpage{
        margin-top: 4.5%;
    }
}
@media only screen and (min-width: 1250px) and (max-width: 1455px)  {
    .Loginpage{
        width: 29%;
    }
}